<template>
  <div class="content">

    <!-- 页面 -->
    <div class="detail_content pb_15">
      <el-row class="h100ratio">
        <!-- 话术列表 -->
        <el-col :span="6" class="h100ratio" ref="ratio6" v-if="show" >
          <div class="talk_craft_box">
            <div class="talk_craft_search">
              <el-input
                  @change="params(talk)"
                  placeholder="请输入内容"
                  prefix-icon="el-icon-search"
                  v-model="talk.question">
              </el-input>
            </div>

            <div class="talk_craft_list">
              <div>
                <el-collapse v-model="talkActive" accordion>
                  <el-collapse-item v-for="(item,index) in talkList" :key="index"
                                    :title="item.question" :name="index">
                    <div class="talk_collapse" v-html="item.answer" ></div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
        </el-col>

        <!-- 详情信息 -->
        <el-col :span="( show ? 18 : 24 )" class="h100ratio" ref="ratio18" >
          <div class="detail_content_box" :class="show ? '' : 'box_after' " >
            <!-- 信息组  基本信息 -->
            <div class="detail_item">
              <div class="item_title">
                基本信息
              </div>
              <div class="item_info">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <div class="item_info_col">
                      <div class="item_info_col_title">
                        姓名：
                      </div>
                      {{ deta.name }}
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="item_info_col">
                      <div class="item_info_col_title">
                        性别：
                      </div>
                      {{ deta.gender == 0 ? "女" : "男" }}
                    </div>
<!--                    <div class="item_info_col">-->
<!--                      <div class="item_info_col_title">-->
<!--                        性别：-->
<!--                      </div>-->
<!--                      女-->
<!--                    </div>-->
                  </el-col>

                  <el-col :span="8">
                    <div class="item_info_col" v-if="deta.age">
                      <div class="item_info_col_title">
                        年龄：
                      </div>
                      {{ deta.age + "岁" }}
                    </div>
                    <div class="item_info_col" v-else>
                      <div class="item_info_col_title">
                        年龄：
                      </div>
                      --
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="item_info_col" v-if="deta.phoneNo">
                      <div class="item_info_col_title">
                        手机号：
                      </div>
                      <el-tooltip effect="dark" :content="deta.phoneNo" placement="top">
                        <p class="name">{{ deta.phoneNo }}</p>
                      </el-tooltip>
                    </div>
                    <div class="item_info_col" v-else>
                      <div class="item_info_col_title">
                        手机号：
                      </div>
                      --
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="item_info_col" v-if="deta.idCardNo">
                      <div class="item_info_col_title">
                        身份证号：
                      </div>
                      <el-tooltip effect="dark" :content="deta.idCardNo" placement="top">
                        <p class="name">{{ deta.idCardNo }}</p>
                      </el-tooltip>
                    </div>
                    <div class="item_info_col" v-else>
                      <div class="item_info_col_title">
                        身份证号：
                      </div>
                      --
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!-- 信息组  分析建议 -->
            <div class="detail_item">
              <div class="item_title">
                分析建议
              </div>
              <div class="item_info">
                <el-table
                    :data="deta.reportAdviceList"
                    height="200px"
                    size="small"
                    @selection-change="handleSelectionChange"
                    :row-class-name="tableRowClassName"
                >
                  <el-table-column
                      v-if="show"
                      type="selection"
                      width="55">
                  </el-table-column>
                  <el-table-column
                      label="科室"
                      align="center"
                      prop="deptName">
                  </el-table-column>
                  <el-table-column
                      label="建议原因"
                      align="center"
                      show-overflow-tooltip
                      prop="reason">
                  </el-table-column>
                  <el-table-column
                      label="诊断结论"
                      align="center"
                      show-overflow-tooltip
                      prop="diagnosis">
                  </el-table-column>
                  <el-table-column
                      label="复诊次数"
                      align="center"
                      show-overflow-tooltip
                      prop="reviewCount">
                  </el-table-column>
                  <el-table-column
                      label="建议复诊日期"
                      align="center"
                      prop="reviewDate">
                  </el-table-column>
                  <el-table-column
                      label="诊断医生"
                      align="center"
                      prop="doctorName">
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- tab功能栏 -->
            <div class="detail_item" style="margin-top: 20px">
              <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" >
                <el-tab-pane label="呼叫记录" name="0" v-if="show" >
                  <div class="tab_content">
                    <el-form ref="form"
                             :rules="rules"
                             :model="form"
                             :hide-required-asterisk="true"
                             style="min-height: 200px; position: relative;"
                             label-width="120px">
                      <el-form-item label="是否接听" prop="answered">
                        <el-select v-model="form.answered"
                                   placeholder="请选择是否接听"
                                   size="small">
                          <el-option label="是" :value="true"></el-option>
                          <el-option label="否" :value="false"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="未接听状态"
                                    v-if="form.answered === false"
                                    prop="answerStatus">
                        <el-select v-model="form.answerStatus"
                                   placeholder="请选择未接听状态"
                                   size="small">
                          <el-option v-for="item in $store.state.answerStatus" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="是否复诊"
                                    prop="review"
                                    v-if="form.answered === true"
                                    size="small">
                        <el-select v-model="form.review"
                                   placeholder="请选择是否复诊">
                          <el-option label="是" :value="true"></el-option>
                          <el-option label="否" :value="false"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="不复诊原因"
                                    v-if="form.review === false && form.answered === true"
                                    prop="unreviewReason"
                                    size="small">
                        <el-input type="textarea" placeholder="请输入不复诊原因" v-model="form.unreviewReason" ></el-input>
                      </el-form-item>
                      <el-form-item label="预约医院"
                                    v-if="form.review === true && form.answered === true"
                                    prop="appointHospital"
                                    size="small">
                        <el-select v-model="form.appointHospital" placeholder="请选择预约医院">
                          <el-option v-for="item in $store.state.followHospital" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="预约科室"
                                    v-if="form.review === true && form.answered === true"
                                    prop="appointDept"
                                    size="small">
                        <el-select v-model="form.appointDept" placeholder="请选择预约科室">
                          <el-option v-for="item in $store.state.followDept" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="预约复诊日期"
                                    v-if="form.review === true && form.answered === true"
                                    prop="appointDate">
                        <el-date-picker
                            v-model="form.appointDate"
                            type="date"
                            size="small"
                            :picker-options="pickerOptions0"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="预约复诊时段"
                                    size="small"
                                    v-if="form.review === true && form.answered === true"
                                    prop="appointPeriod">
                        <el-select v-model="form.appointPeriod" placeholder="请选择预约复诊时段">
                          <el-option :label="item.dictValue" v-for="item in $store.state.follow_appoint" :key="item.dictKey" :value="item.dictKey"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item size="mini"
                                    style="display: flex; justify-content: flex-end; position: absolute; bottom: -18px; right: 0; ">
                        <el-button @click="cancel()">取消</el-button>
                        <el-button type="primary" @click="onSubmit(form)">提交</el-button>
                        <el-button type="primary" @click="Reserved" >已预约</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="历史随访记录" name="1">
                  <div class="tab_content">
                    <el-table
                        :data="history_table"
                        height="290"
                        min-height="200"
                        size="small"
                    >
                      <el-table-column
                          label="是否接听"
                          align="center"
                          prop="answered">
                        <template scope="scope">
                          <span v-if="scope.row.answered">是</span>
                          <span v-else>否</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="是否复诊"
                          align="review"
                          prop="isExamination">
                        <template scope="scope">
                          <span v-if="scope.row.review == true">是</span>
                          <span v-if="scope.row.review == false">否</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="未复诊原因"
                          align="center"
                          show-overflow-tooltip
                          prop="unreviewReason">
                      </el-table-column>
                      <el-table-column
                          label="未接听原因"
                          align="center"
                          prop="answeredStatus">
                        <template slot-scope="scope">
                          <span>{{ scope.row.answeredStatus != null ? $store.state.answerStatus.filter((item) => { return item.dictKey == scope.row.answeredStatus })[0].dictValue : null }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          label="预约复诊日期"
                          align="center"
                          prop="appointDate">
                      </el-table-column>
                      <el-table-column
                          label="随访时间"
                          align="center"
                          prop="callTime">
                      </el-table-column>
                      <el-table-column
                          label="操作人员"
                          align="center"
                          prop="csStaff">
                      </el-table-column>
                      <el-table-column
                          fixed="right"
                          align="center"
                          label="操作">
                        <template slot-scope="scope">
                          <el-button v-if="scope.row.answered && scope.row.review" type="text" size="small" @click="See(scope.row,scope.$index)" >查看详情</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div v-if="!show" style="display: flex; justify-content: flex-end; margin-top: 14px; ">
                    <el-button @click="cancel()" size="small">取消</el-button>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="查看报告" name="2" >
<!--                  <ul class="pdf" v-if="report_json.url != null" >-->
<!--&lt;!&ndash;                    <h2>报告明细-{{ deta.reportTypeName }}</h2>&ndash;&gt;-->
<!--                    <li @click="link_pdf(report_json.url)">-->

<!--                      <img v-if="report_json.reportType == 1" src="../../assets/DoctorDeta/commodity1.png" alt="">-->
<!--                      <img v-if="report_json.reportType == 5" src="../../assets/DoctorDeta/commodity2.png" alt="">-->
<!--                      <img v-if="report_json.reportType == 0" src="../../assets/DoctorDeta/commodity3.png" alt="">-->
<!--                      <img v-if="report_json.reportType == 2" src="../../assets/DoctorDeta/commodity4.png" alt="">-->
<!--                      <img v-if="report_json.reportType == 3" src="../../assets/DoctorDeta/commodity5.png" alt="">-->

<!--                      <p>{{ report_json.reportName }}</p>-->
<!--                    </li>-->
<!--                  </ul>-->

                  <el-image-viewer
                      v-if="showViewerDemo"
                      :on-close="closeViewerDemo"
                      :url-list="img_list" />

                  <ul class="pdf" v-if="report_json.pathList != null && report_json.pathList.length != 0" >
<!--                    <h2>报告明细-{{ report_json.reportTypeName }}</h2>-->
                    <li v-for="(item,index) in report_json.pathList" :key="index">
                      <el-image
                          style="width: 80%; height: 100%;"
                          @click="imgCLick(index)"
                          :src="item">
                      </el-image>
                    </li>
                  </ul>

                  <template v-else>
                    <NoList>
                      <template #text>
                        <p>暂无报告</p>
                      </template>
                    </NoList>
                  </template>
                </el-tab-pane>
              </el-tabs>
            </div>

          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog
        title="详情"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div class="dia">
        <h2 class="title">呼叫预约信息</h2>
        <el-form ref="history_deta_form"
                 :rules="history_deta_form_rules"
                 :model="history_deta_form"
                 :inline="true"
                 :hide-required-asterisk="true"
                 :disabled="!history_deta_form.reviewId"
                 label-width="70px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="预约日期" prop="appointDate">
                <el-date-picker
                    v-model="history_deta_form.appointDate"
                    size="small"
                    type="date"
                    :picker-options="pickerOptions0"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择预约日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="预约时段" prop="appointPeriod">
                <el-select v-model="history_deta_form.appointPeriod"
                           size="small"
                           placeholder="请选择预约时段">
                  <el-option v-for="item in $store.state.follow_appoint" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="预约医院" prop="appointHospital">
                <el-select v-model="history_deta_form.appointHospital"
                           size="small"
                           placeholder="请选择预约医院">
                  <el-option v-for="item in $store.state.followHospital" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="预约科室" prop="appointDept">
                <el-select v-model="history_deta_form.appointDept"
                           size="small"
                           placeholder="请选择预约科室">
                  <el-option v-for="item in $store.state.followDept" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <h2 class="title">呼叫原因及诊断</h2>
        <el-table
            :data="history_deta.reportAdviceList"
            height="100%"
            size="small"

        >
          <el-table-column
              label="科室"
              align="center"
              prop="deptName">
          </el-table-column>
          <el-table-column
              label="建议原因"
              align="center"
              prop="reason">
          </el-table-column>
          <el-table-column
              label="诊断结论"
              align="center"
              prop="diagnosis">
          </el-table-column>
          <el-table-column
              label="随访次数"
              align="center"
              prop="reviewCount">
          </el-table-column>
          <el-table-column
              label="建议复诊日期"
              align="center"
              prop="reviewDate">
          </el-table-column>
          <el-table-column
              label="诊断医生"
              align="center"
              prop="doctorName">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button v-if="history_deta_form.reviewId" size="small" type="primary" @click="onSubmitDia(history_deta_form)">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>
<script>

import NoList from "@/components/common/NoList";
export default {
  name: 'callDetail',
  components: {NoList},
  data() {
    return {
      show: this.$route.query.state == "0" ? true : false, // 判断随访||随访服务记录
      activeName: this.$route.query.state == "2" ? "0" : "1", // 标签页
      form: {
        followId: this.$route.query.id, // 服务ID
        adviceIdList: [], // 随访建议列表
        answered: null, // 是否接听
        answerStatus: null, // 未接通状态
        review: null, // 是否复诊
        unreviewReason: null, // 不复诊原因
        appointHospital: null, // 预约医院
        appointDate: null, // 预约日期
        appointPeriod: null, // 预约时段
        appointDept: null, // 预约科室
      }, // 跟随记录表单
      rules: {
        answered: [
            { required: true, message: '请选择是否接听', trigger: 'change' },
        ],
        answerStatus: [
            { required: true, message: '请选择未接通状态', trigger: 'change' },
        ],
        review: [
            { required: true, message: '请选择是否复诊', trigger: 'change' },
        ],
        unreviewReason: [
            { required: true, message: '请输入不复诊原因', trigger: 'change' },
        ],
        appointHospital: [
            { required: true, message: '请选择预约医院', trigger: 'change' },
        ],
        appointDate: [
            { required: true, message: '请输入预约科室', trigger: 'change' },
        ],
        appointPeriod: [
            { required: true, message: '请选择预约复诊日期', trigger: 'change' },
        ],
        appointDept: [
            { required: true, message: '请选择预约复诊时段', trigger: 'change' },
        ],
      },
      // 预约复诊日期
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 1
        }
      },
      // 详情
      deta: {
        reportAdviceList: []
      },
      // 历史随访记录
      history_table: [],
      // 历史随访记录详情
      dialogVisible: false,
      history_deta: {
        reportAdviceList: []
      },
      history_deta_form: {
        reviewId: null,
        appointDate: null,
        appointPeriod: null,
        appointHospital: null,
        appointDept: null,
      },
      history_deta_form_rules: {
        appointDate: [
            { required: true, message: '请选择预约日期', trigger: 'change' },
        ],
        appointPeriod: [
            { required: true, message: '请选择预约时段', trigger: 'change' },
        ],
        appointHospital: [
            { required: true, message: '请选择预约医院', trigger: 'change' },
        ],
        appointDept: [
            { required: true, message: '请选择预约科室', trigger: 'change' },
        ],
      },
      // 话术
      talkActive: '1', // 展开问题
      talkList: [], // 话术列表
      talk: {
        dialogType: '1', // 话术类型
        hot: true, // 常用问题
        question: null, // 搜索问题
      },
      // 检查报告
      report_json: {},
      // 图片查看器
      showViewerDemo: false,
      img_list: [],
    }
  },
  watch: {
    "form": {
      handler:function() {
        this.$refs.form.clearValidate()
      },
      deep: true
    }
  },
  created() {

    console.log(this.$store.state.follow_appoint)

    console.log(this.$store.state.reportType);

    // 详情
    this.get_deta(this.$route.query.id);
    // 历史随访
    this.get_history(this.$route.query.id);
    // 话术列表
    this.getTalkList(this.talk);
    // 检查报告
    this.get_report(this.$route.query.id);

  },
  update() {
    // console.log(this.$refs.ratio6)
    // console.log(this.$refs.ratio18)
    // this.$refs["ratio6"].$el.scrollHeight= this.$refs["ratio18"].$el.scrollHeight;
  },
  mounted() {

  },
  methods: {
    imgCLick(i) {
      this.img_list= [];

      let leng = this.report_json.pathList.length;
      this.report_json.pathList.forEach((item,index) => {
        if(index - i >= 0) {
          this.img_list[index - i] = item;
        }else {
          this.img_list[index - i + leng] = item;
        }
      })
      this.showViewerDemo = true;

    },
    closeViewerDemo() {
      this.showViewerDemo= false;
    },
    // 已预约按钮
    Reserved() {

      if(this.form.adviceIdList.length != 0) {

        let obj= {};
        obj["adviceIdList"]= this.form.adviceIdList;
        obj["serviceId"]= this.$route.query.id;

        this.$post("cs/follow/repeat",obj)
          .then(res => {
            if(res) {
              this.$router.push({
                path: "/FollowService",
                query: {
                  activeName: this.$route.query.state
                }
              })
            }
          })
      }else {
        this.$message({
          type: "warning",
          message: "请选择呼叫的随访建议"
        })
      }



    },
    // 呼叫历史记录提交
    onSubmitDia(formName) {
      this.$refs["history_deta_form"].validate((valid) => {
        if(valid) {
         console.log(formName);
         this.$put("cs/follow",formName)
            .then(res => {
              if(res) {
                this.dialogVisible= false;
              }
            })

        }
      })
    },
    // 跟随记录提交
    onSubmit(formName) {
      this.$refs["form"].validate((valid) => {
        if(valid) {
          if(this.form.adviceIdList.length != 0) {
            console.log(formName);
            this.$post("cs/follow",formName)
              .then(res => {
                if(res) {
                  console.log(res)

                  this.$router.push({
                    path: "/FollowService",
                    query: {
                      activeName: this.$route.query.state
                    }
                  })

                }
              })
          }else {
            this.$message({
              type: "warning",
              message: "请选择呼叫的随访建议"
            })
          }
        }
      })
    },
    // 获取详情
    get_deta(y) {
      this.$get("cs/follow",y,true)
        .then(res => {
          if(res) {
            console.log(res);

            this.deta= res.data;
            this.form.nswered= true;
            if(res.data.appointDate) {
              this.form.answered= true;
              this.form.review= true;
              this.form.appointHospital= res.data.appointHospital;
              this.form.appointDate= res.data.appointDate;
              this.form.appointPeriod= res.data.appointPeriod;
            }

          }
        })
    },
    /* 获取话术列表 */
    getTalkList(y) {
      this.$get("cs/dialog",y)
        .then(res => {
          if(res) {
            // console.log(res);
            this.talkList= res.data;
          }
        })
    },
    // 话术搜索
    params(formTalk) {
      formTalk.hot= false;
      this.getTalkList(formTalk)
    },
    // 历史列表
    get_history(y) {
      this.$get("cs/follow/history",y,true)
        .then(res => {
          if(res) {
            console.log(res);
            this.history_table= res.data;
          }
        })
    },
    get_report(y) {
      this.$get("cs/follow/report",y,true)
        .then(res => {
          if(res) {
            console.log(res);
            this.report_json= res.data;
          }
        })
    },
    // 历史查看详情
    See(e) {
      this.$get("cs/follow/history/advice",e.callId,true)
        .then(res => {
          if(res) {
            console.log(res);

            this.history_deta= res.data;

            this.dialogVisible= true;

            this.$nextTick(() => {
              this.history_deta_form.appointDate= res.data.appointDate;
              this.history_deta_form.appointPeriod= res.data.appointPeriod;
              this.history_deta_form.appointHospital= res.data.appointHospital;
              this.history_deta_form.appointDept= res.data.appointDept;
              this.history_deta_form.reviewId= res.data.reviewId;

              this.$refs.history_deta_form.clearValidate()

            })

          }
        })
    },
    handleClose() {
      this.dialogVisible= false;
    },
    // 查看pdf
    link_pdf(e) {
      window.open(e, '_blank','toolbar=yes, width=900, height=700');
    },
    // 历史随访查看详情
    call() {

    },
    // 跟随记录取消
    cancel() {
      // this.$refs["form"].resetFields();
      this.$router.push({
        path: "/FollowService",
        query: {
          activeName: this.$route.query.state
        }
      })
    },
    // 复选框
    handleSelectionChange(val) {
      console.log(val);
      this.form.adviceIdList= [];
      val.forEach((item) => {
        this.form.adviceIdList.push(item.adviceId)
      })

    },
    // 分析建议标记
    tableRowClassName() {
      // {row, rowIndex}
      // console.log(row);
      // console.log(rowIndex);

      // if(row.flag) {
      //   return "warning-row";
      // }else {
      //   return "";
      // }
    },
    // 标签页
    handleClick() {
      console.log(this.activeName)
      if(this.activeName == 1) {
        this.get_history(this.$route.query.id);
      }
    },


  },
}
</script>
<style scoped lang="less">
 @import "../../style/Service/cellDeta";
</style>
